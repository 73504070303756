import {Row, Col, Button} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";


export function Info() {
    const navigate = useNavigate();
    useEffect(() => {
        navigate("/galerie", {replace: true});
    });

    return (
        <>
            <Row>
                <Col md={12}>
                    <p className="lead">
                        Vítejte, srdečně Vás zveme na naši svatební hostinu, která se uskuteční <b>20. 8. 2022 v 13
                        hodin</b> v <b>Hotelu Sladovna</b> v <b>Černé Hoře</b>.
                    </p>
                    <h2 className="text-center">Důležité informace</h2>
                    <p className="lead">
                        Hotel nám pro akci vyhradil celé jejich parkoviště - kapacita cca 40 parkovacích míst. Proto by tu měl být dostatek prostoru pro všechny.
                    </p>
                    <p className="lead text-center">
                        <Button target="_blank" href="https://mapy.cz/s/covuhojole">Odkaz na body v mapě</Button>
                    </p>
                    <p className="lead">

                        Začátek hostiny proběhne na nádvoří hotelu. Abyste se tam dostali, je potřebné obejít hotel uličkou mezi pivovarem a hotelem (viz “Odkaz na body na mapě” výše). Nádvoří je dostupné ze strany od náměstí. Na první část programu budeme mít rezervovanou zahrádku, která je zastřešená. Proto se nebojte přímého slunce ani deště.

                    </p>
                    <h2 className="text-center">Pro ubytované</h2>
                    <p className="lead">
                        Check-in je od 14h. Ubytování máte již rezervované na jména, proto se nemusíte bát, že by se vám na pokoj ubytoval někdo před vámi.
                        Na ubytování tedy nemusíte spěchat a ideální časové okno bude po obědě a před večerním programem.
                    </p>
                    <p className="lead">
                        Kdyby vás ještě cokoliv napadlo, dejte nám prosím vědět na email <a target="_blank" href="mailto:svatba@karasek.pro">svatba@karasek.pro</a> a společně to vyřešíme.
                        Stejně tak pokud víte, že se akce nezúčastníte, i když jste se prve přihlásili.
                    </p>
                    <p className="lead text-center">
                        S pozdravem,
                        Karolína & Miroslav
                    </p>
                </Col>
            </Row>
        </>
    );
}