import React from "react";
import Spinner from "react-bootstrap/Spinner";
import {Row} from "react-bootstrap";

export default function Loading() {
    return (
        <Row>
            <span className="text-center mt-5 mb-5">
                <Spinner animation="border"/>
            </span>
        </Row>
    );
}