import {Row, Col, Button} from "react-bootstrap";
import {useParams, useMatch, Link, useNavigate} from "react-router-dom";
import ConfirmDialog from "utils/ConfirmDialog";
import {useEffect, useState} from "react";
import Questioner from "model/Questioner";


export function Intro(props: {
    questioner: Questioner,
    onNotAttend: () => Promise<any>,
}) {
    let [showAlert, setShowAlert] = useState(false);

    let handleClose = () => setShowAlert(false);

    const questionerFilled = props.questioner.isValid();

    const navigate = useNavigate();
    useEffect(() => {
        navigate("/galerie", {replace: true});
    });

    return (
        <>
            <Row>
                <Col md={12}>
                    <p className="lead">
                        Vítejte, srdečně Vás zveme na naši svatební hostinu, která se uskuteční <b>20. 8. 2022 v 13
                        hodin</b> v <b>Hotelu Sladovna</b> v <b>Černé Hoře</b>.
                    </p>
                    <p className="lead">
                        Prosíme o vyplnění stručného dotazníku, abychom věděli na kolik z Vás se můžeme tešit. Dotazník
                        prosím vyplňte nejpozději do konce února 2022. Předem děkujeme.
                    </p>
                    {questionerFilled && (
                        <p className="text-center">
                            Dotazník vyplněn
                        </p>
                    )}
                </Col>
            </Row>
            <Row>
                <Col xs={6} className="text-end">
                    <Link to="vyplnit" className="btn btn-primary">{questionerFilled ? "Upravit" : "Vyplnit"} dotazník</Link>
                </Col>
                {props.questioner.attend && (
                    <Col xs={6}>
                        <Button variant="outline-primary" onClick={() => setShowAlert(true)}>Nezůčastním se</Button>
                    </Col>
                )}
            </Row>
            <ConfirmDialog title="Jsi si jistý?" ackText="Ano" nackText="Zrušit" ackVariant="danger" show={showAlert} onConfirm={props.onNotAttend} onClose={handleClose}/>
        </>
    );
}