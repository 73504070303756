import {Row, Col, Button, Form} from "react-bootstrap";
import Questioner from "model/Questioner";
import React, {useState} from "react";
import LoadingButton from "components/LoadingButton";
import {notSaved, notValid, saved} from "utils/Alerts";
import {useAlert} from "react-alert";
import {useNavigate} from "react-router-dom";


export function QuestionerForm(props: {
    questioner: Questioner,
    onUpdate: (questioner: Questioner) => Promise<any>,
}) {
    let [name, setName] = useState(props.questioner.name)
    let [contact, setContact] = useState(props.questioner.contact)
    let [people, setPeople] = useState(props.questioner.people)
    let [onMeal, setOnMeal] = useState(props.questioner.onMeal)
    let [kidsMeals, setKidsMeals] = useState(props.questioner.kidsMeals)
    let [vegeMeals, setVegeMeals] = useState(props.questioner.vegeMeals)
    let [noMeals, setNoMeals] = useState(props.questioner.noMeals)
    let [onAccommodated, setOnAccommodated] = useState(props.questioner.onAccommodated)
    let [accommodatedKids, setAccommodatedKids] = useState(props.questioner.accommodatedKids)
    let [note, setNote] = useState(props.questioner.note)

    let [loading, setLoading] = useState(false)

    const alert = useAlert();
    const navigate = useNavigate();

    let save = () => {
        setLoading(true);
        props.onUpdate(Object.assign(new Questioner(), props.questioner, {
            attend: true,
            name: name,
            contact: contact,
            people: people,
            onMeal: onMeal,
            kidsMeals: kidsMeals,
            vegeMeals: vegeMeals,
            noMeals: noMeals,
            onAccommodated: onAccommodated,
            accommodatedKids: accommodatedKids,
            note: note,
        }))
            .then(() => {
                saved(alert);
                navigate("/", {replace: true});
            })
            .catch(() => notSaved(alert))
            .finally(() => setLoading(false));
    }

    const onMealCheckboxChanged = (checked: boolean) => {
        if (checked) {
            setKidsMeals(0)
            setVegeMeals(0)
            setNoMeals(0)
        } else {
            setKidsMeals(NaN)
            setVegeMeals(NaN)
            setNoMeals(NaN)
        }
        setOnMeal(checked)
    }

    const onAccommodatedCheckboxChanged = (checked: boolean) => {
        if (checked) {
            setAccommodatedKids(0)
        } else {
            setAccommodatedKids(NaN)
        }
        setOnAccommodated(checked)
    }

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event: any) => {
        // Always stop propagation
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;
        if (form.checkValidity() === true) {
            // Save valid form
            save();
        } else {
            notValid(alert);
        }

        setValidated(true);
    };

    return (
        <>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Jméno kontaktní osoby / rodiny</Form.Label>
                            <Form.Control placeholder="" value={name} required
                                          onChange={(e) => setName(e.target.value)}/>
                            <Form.Text className="text-muted">
                                Abychom věděli, ke komu vás přiřadit
                            </Form.Text>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Kontaktní údaj</Form.Label>
                            <Form.Control placeholder="E-mail / Telefon" value={contact} required
                                          onChange={(e) => setContact(e.target.value)}/>
                            <Form.Text className="text-muted">
                                Využijeme ho jen v případě, když se něco bude měnit
                            </Form.Text>
                        </Form.Group>
                    </Col>
                    <Col md={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Počet osob</Form.Label>
                            <Form.Control type="number" min={1} placeholder="Celkový počet osob (dospělých i dětí)" value={people} required
                                          onChange={(e) => setPeople(parseInt(e.target.value))}/>
                        </Form.Group>
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col md={12}>
                        <Form.Group className="mb-3">
                            <Form.Label>Zúčastníte se hostiny ve 13h?</Form.Label>
                            <Form.Check type="switch" className="form-switch" checked={onMeal}
                                        onChange={(e) => onMealCheckboxChanged(e.target.checked)}/>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Počet dětských porcí</Form.Label>
                            <Form.Control type="number" min={0} max={people} placeholder="" value={kidsMeals} disabled={!onMeal} required={onMeal}
                                          onChange={(e) => setKidsMeals(parseInt(e.target.value))}/>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Počet vegetariánských porcí</Form.Label>
                            <Form.Control type="number" min={0} max={people} placeholder="" value={vegeMeals} disabled={!onMeal} required={onMeal}
                                          onChange={(e) => setVegeMeals(parseInt(e.target.value))}/>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Počet osob bez jídla</Form.Label>
                            <Form.Control type="number" min={0} max={people} placeholder="" value={noMeals} disabled={!onMeal} required={onMeal}
                                          onChange={(e) => setNoMeals(parseInt(e.target.value))}/>
                            <Form.Text className="text-muted">
                                Například malé děti
                            </Form.Text>
                        </Form.Group>
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col md={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Budete chtít ubytování?</Form.Label>
                            <Form.Check type="switch" className="form-switch" checked={onAccommodated}
                                        onChange={(e) => onAccommodatedCheckboxChanged(e.target.checked)}/>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Dětí do 5 let</Form.Label>
                            <Form.Control min={0} max={people} type="number" placeholder="" value={accommodatedKids} disabled={!onAccommodated} required={onAccommodated}
                                          onChange={(e) => setAccommodatedKids(parseInt(e.target.value))}/>
                        </Form.Group>
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col md={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Poznámky</Form.Label>
                            <Form.Control as="textarea" rows={3} value={note} placeholder="Sem nám můžete napsat další informace nebo dotazy"
                                          onChange={(e) => setNote(e.target.value)}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xl={12} className="text-center">
                        <LoadingButton type="submit" size="lg" text="Uložit" isLoading={loading}/>
                    </Col>
                </Row>
            </Form>
        </>
    );
}