import {Routes, Route, useParams} from "react-router-dom";
import React from "react";
import {FirebaseAuthConsumer} from "@react-firebase/auth";
import {QuestionerEdit, QuestionerInfo} from "pages/Questioner";
import {Authorization} from "pages/Authorization";
import {FirestoreDocument} from "@react-firebase/firestore";
import Loading from "components/Loading";
import User from "model/User";
import {Info} from "components/Info";
import {Gallery} from "components/Gallery";
import firebase from "firebase/app";


export function Authentication() {
    return (
        <FirebaseAuthConsumer>
            {({isSignedIn, user, providerId}) => {
                if (!isSignedIn || user === null) {
                    return (
                        <NotAuthenticated/>
                    );
                }

                return <Authenticated userId={user.uid}/>
            }}
        </FirebaseAuthConsumer>
    );
}

export function NotAuthenticated() {
    firebase.auth().signInAnonymously().catch(((reason: any) => console.log(reason)));

    return (<Loading />);
}

export function Authenticated(props: {
    userId: string,
}) {

    const userId = props.userId
    const basePath = "/users/";

    return (

        <FirestoreDocument path={basePath + userId}>
            {d => {
                if (d.isLoading !== false) {
                    return <Loading/>;
                }

                let user = new User(d.value, userId);

                return (
                    <Routes>
                        <Route path="/info" element={<Info/>}/>
                        <Route path="/galerie" element={<Gallery/>}/>
                        <Route path="/vyplnit" element={<QuestionerEdit user={user}/>}/>
                        <Route path="/:id" element={<Authorization user={user}/>}/>
                        <Route path="*" element={<QuestionerInfo user={user}/>}/>
                    </Routes>
                );
            }}
        </FirestoreDocument>
    );
}