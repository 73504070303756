import React, {useState} from "react";
import Image, {ImageProps} from "react-bootstrap/Image";
import 'firebase/storage';
import firebase from "firebase/app";


export default function FirebaseImage(props: ImageProps) {
    let [imageSrc, setImageSrc] = useState<string>();
    let [imageUrl, setImageUrl] = useState<string>();

    let idToUrl = function (id: string) {
        if (id == null) {
            return;
        }
        let optimizedId: string|undefined = undefined;

        if (id.startsWith("http") || id.startsWith("/")) {
            setImageUrl(id);
        } else {
            if (optimizedId !== undefined) {
                firebase.storage().refFromURL(optimizedId).getDownloadURL().then(setImageUrl).catch(() => {
                    firebase.storage().refFromURL(id).getDownloadURL().then(setImageUrl);
                });
            } else {
                firebase.storage().refFromURL(id).getDownloadURL().then(setImageUrl);
            }
        }
    }

    let changed = false;
    if (imageSrc !== props.src && props.src !== undefined) {
        setImageSrc(props.src);
        changed = true;
    }

    if (changed && props.src !== undefined) {
        idToUrl(props.src);
    }

    return (
        <Image {...props} src={imageUrl} />
    );
}