import React from 'react';
import {Container, Row, Col} from "react-bootstrap";

import {
    BrowserRouter, Link
} from "react-router-dom";

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import {firebaseConfig} from 'config';
import {FirebaseAuthProvider} from "@react-firebase/auth";
import {FirestoreProvider} from "@react-firebase/firestore";

import 'App.css';
import {Authentication} from "pages/Authentication";


function App() {
    return (
        <FirebaseAuthProvider firebase={firebase} {...firebaseConfig}>
            <FirestoreProvider firebase={firebase} {...firebaseConfig}>
                <BrowserRouter>
                    <Container>
                        <Row className="justify-content-md-center">
                            <Col md={10} lg={9} xl={8} className="text-center">
                                <Link to="" className="text-decoration-none">
                                    <h1 className="m-5">Karolína & Miroslav</h1>
                                </Link>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Authentication/>
                            </Col>
                        </Row>
                    </Container>
                    <footer className="footer text-center bg-light pt-2">
                        <a href="https://www.sharkies.cz" target="_blank" className="text-decoration-none">
                            Sharkies.cz
                        </a>
                    </footer>
                </BrowserRouter>
            </FirestoreProvider>
        </FirebaseAuthProvider>
    );
}

export default App;
