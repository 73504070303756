

export function validNumber(input?: number): number | undefined {
    if (input === undefined || isNaN(input))
        return undefined;

    return input;
}

export function validString(input?: string): string | undefined {
    if (input === undefined)
        return undefined;

    let trimmed = input.trim()
    if (trimmed.length > 0)
        return trimmed;
    else
        return undefined
}