import {Route, Routes, useNavigate, useParams} from "react-router-dom";
import React from "react";
import Loading from "components/Loading";
import User from "model/User";
import {FirestoreDocument, FirestoreMutation} from "@react-firebase/firestore";
import Invitation from "model/Invitation";


export function Authorization(props: {
    user: User,
}) {

    const basePath = "/invitations/";
    const {id} = useParams();
    const invitationId = id?.toUpperCase()

    return (
        <FirestoreDocument path={basePath + invitationId}>
            {d => {
                if (d.isLoading !== false) {
                    return <Loading/>;
                }

                if (d.value === undefined || d.value === null) {
                    return <WrongAuthorization/>;
                }

                let data = new Invitation(d.value, invitationId);

                return (
                    <Authorizator invitation={data} user={props.user}/>
                );
            }}
        </FirestoreDocument>
    );
}

function Authorizator(props: {
    invitation: Invitation,
    user: User,
}) {
    const navigate = useNavigate();

    const userId = props.user.id
    const basePath = "/users/";

    return (
        <FirestoreMutation
            type="set"
            path={basePath + userId}
        >
            {({runMutation}) => {
                runMutation(
                    Object.assign(
                        new User(),
                        props.user, {
                            invitationId: props.invitation.id,
                            updated: new Date(),
                        }
                    ).convert()
                ).then(() => {
                    navigate("/", {replace: true});
                })
                return <Loading/>
            }}
        </FirestoreMutation>
    );
}

function WrongAuthorization() {
    return (
        <>
            <p className="lead text-center">
                Vypadá to, že jste zadali špatnou adresu
            </p>
        </>
    );
}