import {validString} from "utils/Validation";
import {InvitationFb} from "model/firebase/InvitationFb";


export default class Invitation {
    id?: string;
    note?: string;

    constructor(from?: InvitationFb, id?: string) {
        this.id = id;
        this.note = from?.note;
    }

    convert(): InvitationFb {
        return {
            note: validString(this.note),
        };
    }
}