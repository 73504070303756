import Questioner from "model/Questioner";
import {UserFb} from "model/firebase/UserFb";
import firebase from "firebase/app";


export default class User {
    id?: string;
    invitationId?: string;
    questioner: Questioner;
    updated: Date;

    constructor(from?: UserFb, id?: string) {
        this.id = id;
        this.invitationId = from?.invitationId;
        this.questioner = new Questioner(from?.questioner);
        this.updated = from?.updated?.toDate() ?? new Date();
    }

    convert(): UserFb {
        return {
            invitationId: this.invitationId,
            questioner: !this.questioner.isValid() ? undefined : this.questioner.convert(),
            updated: firebase.firestore.Timestamp.fromDate(this.updated),
        }
    }
}