import {AlertManager} from "react-alert";


export function saved(alertManager: AlertManager) {
    alertManager.success("Uloženo");
}

export function notSaved(alertManager: AlertManager) {
    alertManager.error("Uložení se nepodařilo");
}

export function notValid(alertManager: AlertManager) {
    alertManager.error("Některé položky nejsou správně vyplněny");
}