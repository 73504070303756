import React from "react";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import {ButtonVariant} from "react-bootstrap/types";

export default function LoadingButton(props: {
    text: string,
    type?: 'submit' | 'reset' | 'button' | undefined,
    isLoading?: boolean,
    size?: 'sm' | 'lg',
    variant?: ButtonVariant,
    className?: string,
    onClick?: () => void
}) {
    return (
        <Button size={props.size} variant={props.variant} type={props.type} className={props.className} onClick={props.onClick} disabled={props.isLoading}>
            {props.isLoading && (
                <span className="spinner-border spinner-border-sm m-1 mr-2" role="status" aria-hidden="true"/>
            )}
            {props.text}
        </Button>
    );
}