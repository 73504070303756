import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React, {useState} from "react";
import {ButtonVariant} from "react-bootstrap/types";
import LoadingButton from "components/LoadingButton";


export default function ConfirmDialog(props: {
    show: boolean,
    title: string,
    text?: string,
    ackText: string,
    nackText: string,
    ackVariant?: ButtonVariant,
    nackVariant?: ButtonVariant,
    onConfirm: () => Promise<any> | void,
    onClose: () => void,
}) {
    let [showLoading, setShowLoading] = useState(false);

    let onConfirm = () => {
        let promise = props.onConfirm()
        if (promise !== undefined) {
            setShowLoading(true)
            promise.finally(() => {
                setShowLoading(false)
                props.onClose()
            })
        } else {
            props.onClose()
        }
    }

    return (
        <Modal show={props.show} onHide={props.onClose}>
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>

            {
                props.text !== undefined && (
                    <Modal.Body>
                        <p>{props.text}</p>
                    </Modal.Body>
                )
            }

            <Modal.Footer>
                <Button variant={props.nackVariant ?? "secondary"} onClick={props.onClose}>
                    {props.nackText}
                </Button>
                <LoadingButton isLoading={showLoading} variant={props.ackVariant ?? "primary"} onClick={onConfirm}
                               text={props.ackText}/>
            </Modal.Footer>
        </Modal>
    );
}

export function DeleteDialog(props: {
    show: boolean,
    onConfirm: () => Promise<any> | void,
    onClose: () => void,
}) {
    return ConfirmDialog({
        show: props.show,
        title: "Varování",
        text: "Opravdu chcete smazat položku?",
        nackText: "Zrušit",
        ackText: "Smazat",
        ackVariant: "danger",
        onConfirm: props.onConfirm,
        onClose: props.onClose,
    });
}