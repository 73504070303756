import {validNumber, validString} from "utils/Validation";
import {QuestionerFb} from "model/firebase/QuestionerFb";


export default class Questioner {
    id?: string;
    attend: boolean;
    name: string;
    contact: string;
    people: number;
    onMeal: boolean;
    kidsMeals: number;
    vegeMeals: number;
    noMeals: number;
    onAccommodated: boolean;
    accommodatedKids: number;
    note: string;

    constructor(from?: QuestionerFb, id?: string) {
        this.id = id;
        this.attend = from?.attend ?? true;
        this.name = from?.name ?? "";
        this.contact = from?.contact ?? "";
        this.people = from?.people ?? NaN;
        this.onMeal = from?.onMeal ?? true;
        this.kidsMeals = from?.kidsMeals ?? 0;
        this.vegeMeals = from?.vegeMeals ?? 0;
        this.noMeals = from?.noMeals ?? 0;
        this.onAccommodated = from?.onAccommodated ?? false;
        this.accommodatedKids = from?.accommodatedKids ?? NaN;
        this.note = from?.note ?? "";
    }

    convert(): QuestionerFb {
        return {
            attend: this.attend,
            name: validString(this.name),
            contact: validString(this.contact),
            people: validNumber(this.people),
            onMeal: this.onMeal,
            kidsMeals: validNumber(this.kidsMeals),
            vegeMeals: validNumber(this.vegeMeals),
            noMeals: validNumber(this.noMeals),
            onAccommodated: this.onAccommodated,
            accommodatedKids: validNumber(this.accommodatedKids),
            note: validString(this.note),
        }
    }

    isEmpty(): boolean {
        return validString(this.name) === undefined
            && validString(this.contact) === undefined
            && validNumber(this.people) === undefined
            && validNumber(this.accommodatedKids) === undefined
            && validNumber(this.kidsMeals) === undefined
            && validNumber(this.vegeMeals) === undefined
            && validNumber(this.noMeals) === undefined
            && validString(this.note) === undefined;
    }

    isValid(): boolean {
        const name = validString(this.name);
        const contact = validString(this.contact);
        const people = validNumber(this.people);
        const accommodatedKids = validNumber(this.accommodatedKids);
        const kidsMeals = validNumber(this.kidsMeals);
        const vegeMeals = validNumber(this.vegeMeals);
        return !this.attend ||
        (name !== undefined
            && contact !== undefined
            && people !== undefined
            && accommodatedKids !== undefined
            && kidsMeals !== undefined
            && vegeMeals !== undefined);
    }
}