import {Row, Col, Button, Card} from "react-bootstrap";
import FirebaseImage from "components/FirebaseImage";
import {useEffect, useState} from "react";
import firebase from "firebase/app";
import "firebase/storage";

type Reference = firebase.storage.Reference;


export function Gallery(props: {
    dir?: string,
}) {
    let [images, setImages] = useState<Reference[]>();

    const storageRef = firebase.storage().ref();
    const dirRef = props.dir !== undefined ? storageRef.child(props.dir) : storageRef;

    useEffect(() => {
        dirRef.listAll().then((res) => {
            setImages(res.items);
        });
    });

    const imageCells = (images ?? []).map((ref) => {
        const url = `gs://${ref.bucket}/${ref.fullPath}`;
        return (
            <Col lg={12} className="mb-3">
                <Card className="w-100 d-100">
                    <FirebaseImage src={url} className="card-img"/>
                </Card>
            </Col>
        )
    });

    return (
        <>
            <Row>
                {imageCells}
            </Row>
        </>
    );
}