import React from "react";
import {QuestionerForm} from "components/Questioner";
import {FirestoreMutation} from "@react-firebase/firestore";
import User from "model/User";
import Questioner from "model/Questioner";
import {Intro} from "components/Intro";

export function QuestionerEdit(props: {
    user: User,
}) {
    if (props.user.invitationId === undefined) {
        return <NotAuthorized/>
    }
    const userId = props.user.id
    const basePath = "/users/";

    return (
        <>
            <FirestoreMutation
                type="set"
                path={basePath + userId}
            >
                {({runMutation}) => {
                    return (
                        <QuestionerForm
                            questioner={props.user.questioner}
                            onUpdate={questioner => runMutation(
                                Object.assign(
                                    new User(),
                                    props.user,
                                    {
                                        questioner: questioner,
                                        updated: new Date(),
                                    }
                                ).convert())}
                        />
                    );
                }}
            </FirestoreMutation>
        </>
    );
}

export function QuestionerInfo(props: {
    user: User,
}) {
    if (props.user.invitationId === undefined) {
        return <NotAuthorized/>
    }
    const userId = props.user.id
    const basePath = "/users/";

    return (
        <>
            <FirestoreMutation
                type="set"
                path={basePath + userId}
            >
                {({runMutation}) => {
                    return (
                        <Intro
                            questioner={props.user.questioner}
                            onNotAttend={() => {
                                const questioner = new Questioner();
                                questioner.attend = false;
                                return runMutation(
                                    Object.assign(
                                        new User(),
                                        props.user,
                                        {
                                            questioner: questioner,
                                            updated: new Date(),
                                        }
                                    ).convert()
                                )
                            }}/>
                    );
                }}
            </FirestoreMutation>
        </>
    );
}

function NotAuthorized() {
    return (
        <>
            <p className="lead text-center">
                Nacházíte se na stránce věnované naší svatbě. Pro více informací, prosím, vložte celou adresu webové
                stránky, kterou jste dostali na průvodním dopise. Nebo naskenujte QR kód.
            </p>
            <p className="lead text-center">
                Pokud nevíte jak na to, kontaktujte nás prosím
            </p>
        </>
    );
}